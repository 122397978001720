/* 
 * @Author: 曹俊杰 
 * @Date: 2022-05-23 14:43:29
 * @Module: 员工列表
 */
 <template>
  <div class="AccountColleague">
    <el-card shadow="never">
      <div slot="header" class="title">
        <span>{{user.company}}</span>
        <el-divider direction="vertical"></el-divider>
        <span>共
          <span style="color:#0062FF;">{{list.length}}</span>
          个成员</span>
        <el-button style="float: right; margin-right:20px;" size="small" type="primary" @click="visible=true" :disabled="!hasAuth">添加员工</el-button>
      </div>
      <authorized :hasAuth.sync="hasAuth" />
      <el-table :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
        <el-table-column label="账号信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <i class="el-icon-user" style="color:#0062FF;"></i>
            {{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="手机号" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="身份" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>2
              <el-tag type="warning" size="mini" v-if="scope.row.creator">法人</el-tag>
              <el-tag v-else size="mini">员工</el-tag>
              <span v-if="user.uuid===scope.row.uuid">
                <el-divider direction="vertical"></el-divider> <span style="color:#0062FF;">当前帐号</span>
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :resizable="false">
          <template slot-scope="scope">
            <el-link :underline="false" :disabled="!hasAuth" slot="reference" type="info" @click="()=>operate('delete',scope)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <personnelSelection ref="personnelSelection" :multiple="false" :validate="[]" @submit="submit" :visible.sync="visible">
      <el-link type="warning" icon="el-icon-info" :underline="false">请先让员工注册本系统，并完成实名认证后，才可被添加</el-link>
    </personnelSelection>
  </div>
</template>
 <script>
import authorized from "@/components/authorized.vue"
import personnelSelection from "@/components/personnelSelection.vue"
import { mapState } from "vuex";
export default {
  components: {
    authorized,
    personnelSelection
  },
  data () {
    return {
      list: [],
      hasAuth: true,
      visible: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted () {
    this.getAccountColleagueList()
  },
  methods: {
    async getAccountColleagueList () {
      const { data, status } = await this.$api.getAccountColleagueList()
      if (status === 200)
        this.list = data;
    },
    async submit (arr) {
      const { status, info } = await this.$api.putAccount({ empUuid: arr[0].empUuid });
      if (status == 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.visible = false;
        this.getAccountColleagueList()
      } else {
        this.$message({
          type: "error",
          message: info
        })
      }
    },
    operate (type, data) {
      if (type === 'delete') {
        this.$confirm(`此操作将删除员工${data.row.name}, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async (res) => {
          if (res == 'confirm') {
            const { status, info } = await this.$api.deleteAccount({ empUuid: data.row.uuid });
            if (status === 200) {
              const index = this.list.findIndex(item => data.row.uuid == item.uuid);
              this.list.splice(index, 1)
              this.$message({
                type: 'success',
                message: info
              });
            } else {
              this.$message({
                type: 'error',
                message: info
              })
            }
          }
        }).catch(() => ({}));
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.AccountColleague {
  .title {
    line-height: 50px;
    background: #ffffff;
    margin-top: 10px;
    padding-left: 24px;

    font-size: 16px;
    font-weight: 500;
    color: #262626;
    span:last-child {
      color: #0062ff;
      font-size: 16px;
    }
  }
}
</style>